


















































































































































import { Component, Vue } from "vue-property-decorator";
import {
    isAndroid,
    isChrome,
    isChromeStandaloneMode,
    isFirefox,
    isInStandaloneMode,
    isIos,
    isIosStandaloneMode,
    isSafari,
    isWebView,
} from "@/plugins/utils";
import { routes } from "@/router";
import { Location, RouteConfig } from "vue-router";
import { captureSentryException, captureSentryMessage } from "@/plugins/sentry";
import firebase from "@/plugins/firebase";
import ErrorService, { ValidationErrors } from "@/services/errors";
import SmartcardApi from "@/services/api/smartcard";
import { AxiosError, AxiosResponse } from "axios";
import Smartcard from "@/models/smartcard";
import { mdiAlert, mdiCheck, mdiContentCopy } from "@mdi/js";
import { ApiResponse } from "@/services/api/axios";

@Component
export default class KitchenSinkIndex extends Vue {
    input: string = "";
    copyIcon: string = mdiContentCopy;
    isIos: boolean = isIos();
    isStandalone: boolean = isInStandaloneMode();
    isSafari: boolean = isSafari();
    isChrome: boolean = isChrome();
    isChromeStandalone: boolean = isChromeStandaloneMode();
    isIosStandalone: boolean = isIosStandaloneMode();
    isFirefox: boolean = isFirefox();
    isAndroid: boolean = isAndroid();
    userAgent: string = window.navigator.userAgent;
    active: string | null = null;
    formInputErrors: ValidationErrors = new ValidationErrors();
    formSmartCardNumber: string = "";
    isValidatingSmartcardNumber = false;
    smartCardNumberIsValid = false;
    smartcard: Smartcard | null = null;
    alertIcon = mdiAlert;
    tickIcon = mdiCheck;

    get isWebView(): boolean {
        return isWebView();
    }

    resetWorker() {
        navigator.serviceWorker.getRegistrations().then((r) => {
            console.info(r);
            return Promise.all(r.map((reg) => reg.unregister()));
        });
        firebase.messaging();
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.formInputErrors = ErrorService.getValidationErrors(error);
        this.handleError(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }

    async copySmartcardName() {
        try {
            await navigator.clipboard.writeText(
                this.smartcard?.customerName ?? ""
            );
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.SUCCESS,
                "Customer name has been copied to your clipboard"
            );
        } catch (error) {
            captureSentryException(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any) {
        if (error instanceof Error && error.message) {
            captureSentryException(error);
            this.$root.$emit(
                this.$constants.NOTIFICATION_EVENTS.ERROR,
                error.message
            );
            return;
        }

        captureSentryMessage(error);
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            "We could not carry out your request. It may be because your internet connection is not so good. Please try again"
        );
    }

    validateSmartcardNumber() {
        this.isValidatingSmartcardNumber = true;
        this.smartcard = null;
        this.smartCardNumberIsValid = false;

        this.$recaptcha("validate_smartcard")
            .then((token: string) => {
                SmartcardApi.create(this.formSmartCardNumber, token)
                    .then((response: AxiosResponse) => {
                        this.formInputErrors.clear("smartcard_number");
                        this.smartcard = new Smartcard(response.data.data);
                        this.smartCardNumberIsValid = true;
                    })
                    .catch(this.handleAxiosError)
                    .finally(() => {
                        this.isValidatingSmartcardNumber = false;
                    });
            })
            .catch((error) => {
                this.isValidatingSmartcardNumber = false;
                this.handleError(error);
            });
    }

    showNotification() {
        if (Notification.permission == "granted") {
            navigator.serviceWorker
                .getRegistration()
                .then((reg: ServiceWorkerRegistration | undefined) => {
                    if (!reg) {
                        captureSentryException(
                            new Error("no service worker registration")
                        );
                        return;
                    }

                    reg.showNotification("Hello world!", {
                        body: "Testing Body",
                        icon: "https://nyangapay.com/img/icons/android-chrome-192x192.png",
                    });
                });
        } else {
            captureSentryException(new Error("permission not granted"));
        }
    }

    routes() {
        const header: Array<Location> = [];
        routes.forEach((route: RouteConfig) => {
            if (!route.beforeEnter) {
                header.push({
                    path: route.path,
                });
            }
        });
        this.input = JSON.stringify(header, undefined, 4);
    }

    get parseInput(): string {
        return this.input
            .split("\\r\\n")
            .join("<br/>")
            .split("\\n")
            .join("<br/>")
            .split('\\"')
            .join('"')
            .split("\\t")
            .join("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;");
    }
}
